import { Pipe, PipeTransform } from '@angular/core';

/** Returns object name, from an array of objects, based on its ID */
@Pipe({
	name: 'getObjectName'
})
export class GetObjectNamePipe implements PipeTransform {

	transform(objectId: any, arrayOfObjects: any[]): string {
		const foundObject = arrayOfObjects.find(obj => obj.id === objectId);
		return (foundObject?.name || foundObject?.fullName || '');
	}

}
