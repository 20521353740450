import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'getArrayNames'
})
export class GetArrayNamesPipe implements PipeTransform {

	transform(ids: any[], arrayOfAllObj: any[]): string {
		// console.log(`ids --> `, ids);
		// console.log(`arrayOfAllObj --> `, arrayOfAllObj);

		const names = [];
		ids?.forEach(id => {
			const foundObj = arrayOfAllObj.find (obj => {
				return id === obj.id;
			});
			if (foundObj) names.push(foundObj.name || foundObj.fullName || foundObj.text);
		});

		let textToShow = '';
		names.forEach( (name: string, index: number) => {
			textToShow += name;
			if (index === names.length - 2) { // next to last
				textToShow += ' & ';
			}
			else if (index !== names.length - 1) { // not the last obj
				textToShow += ', ';
			}
		});

		return textToShow;
	}

}
